import { FunctionComponent } from 'react'
import { makeStyles, Typography } from '@material-ui/core'

export type Props = {
    description: string,
}

const FormInlineError: FunctionComponent<Props> = ({ description }) => {
    const styles = useStyles()

    return (
        <div
            className={styles.root}
            data-cy='form-inline-error'
        >
            <Typography
                className={styles.text}
                variant='body1'
            >{description}</Typography>
        </div>
    )
}
const useStyles = makeStyles(() => ({
    root: {
        backgroundColor: '#ffeaea',
        padding: '5px 10px',
        borderRadius: 5,
    },
    text: {
        color: 'red',
        fontWeight: 500,
    }
}))

export default FormInlineError