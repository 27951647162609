import { getMonth, getDay, startOfMonth, parseISO } from "date-fns";
import { format } from "date-fns";
import { stringDateFormat } from "../constants";
import { RootState } from "../state/store";
import {
  BookingDTO,
  CustomerBookingDTO,
  BookingElement,
  BookingElementType,
  Brand,
  CustomerBooking,
  PhoneNumber,
} from "../types/bookingTypes";
import { CapacityDate, ServiceCapacity } from "../types/calendarTypes";
import { StepperError, StepperErrorId } from "../types/errorTypes";

/**
 * Calculates the maximum month that is available based on a count of months that are allowed.
 * @param maxAllowed Max count of months in the future that is allowed
 * @returns The max month number that is allowed, e.g. 0 for january, 1 for febuary etc.
 */
export const getMaxMonthAvailable = (maxAllowed: number, startDate?: Date) => {
  const thisMonth = startDate ? getMonth(startDate) : getMonth(new Date());
  let maxMonth = ((thisMonth + maxAllowed) % 12) - 1;
  return maxMonth < 0 ? 11 : maxMonth;
};

/**
 * Converts a list of Brands to a list of key-value objects.
 * @param brands List of brands to convert
 * @returns A list of key-value objects, consisting of the id as key and the name as value
 */
export const convertBrandsToKeyValuePairs = (brands: Brand[]) => {
  return brands.map((b) => ({ key: b.id, value: b.name }));
};

/**
 * Function the get the index of the first weekday of the month, ranging from 1 - 7 starting on monday.
 * @param month The month to check
 * @param year The year to check
 */
export const getFirstWeekdayOfMonth = (month: number, year: number) => {
  let c = getDay(startOfMonth(new Date(year, month)));
  if (c === 0) return 7; // Remove this line to make it 0-based, and sunday = 0.
  return c;
};

/**
 * Gets all the days of a month as Date-objects.
 * @param month The given month
 * @param year The given year
 * @returns An array of Date-objects.
 */
export const getDatesOfMonth = (month: number, year: number) => {
  const date = new Date(year, month, 1);
  const dates: Date[] = [];
  while (date.getMonth() === month) {
    dates.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }
  return dates;
};

export function sanitizePhoneNumber(phoneNumber: PhoneNumber | string): string {
  if (typeof phoneNumber === "string") {
    return phoneNumber.replace(/\D/g, "");
  } else {
    const national = phoneNumber.nationalNumber.replace(/\D/g, "");
    const countryCode = phoneNumber.countryCode.replace(/\D/g, "");
    return countryCode + national;
  }
}

export function formatDateToString(date: Date): string {
  return format(date, stringDateFormat);
}

/**
 * Function to create an array of Promises that represents post-requests for each booking element.
 * @param bookings BookingDTOs to create promises for.
 * @returns Array of promises.
 */
export function createBookingPostRequestsPromises(
  storeName: string,
  customerBooking: CustomerBooking,
  baseURL: string,
  promoCode?: string
) {
  const promises: Promise<Response>[] = [];

  const booking: CustomerBookingDTO = createBookingDTO(
    customerBooking,
    promoCode
  );
  const headers = new Headers();
  headers.append("Content-Type", "application/json");

  let url = `${baseURL}serviceBookings?storeName=${storeName}`;
  if (customerBooking.bookingElements.some((el) => el.bikebox)) {
    url = `${baseURL}bikeboxServiceBookings?storeName=${storeName}`;
  }

  const options = {
    method: "POST",
    headers,
    body: JSON.stringify(booking),
  };

  promises.push(fetch(url, options));

  return promises;
}

export function createBookingDTO(
  customerBooking: CustomerBooking,
  promoCode?: string
): CustomerBookingDTO {
  const bookings: CustomerBookingDTO["bookings"] = [];

  if (!customerBooking.deliveryDate)
    throw new Error("Could not create BookingDTOs from booking.");

  customerBooking.bookingElements.forEach((bookingElement) => {
    if (!bookingElement.brand)
      throw new Error("Could not create BookingDTOs from booking.");

    const booking = {
      brand: bookingElement.brand!,
      type: bookingElement.type,
      description: bookingElement.description,
      bikeboxReservation: bookingElement.bikebox
        ? {
            firstName: customerBooking.firstname,
            lastName: customerBooking.surname,
            phoneNumber: `+${sanitizePhoneNumber(
              customerBooking.customerPhone
            )}`,
          }
        : undefined,
    };
    bookings.push(booking);
  });

  const sanitizedContryCode = sanitizePhoneNumber(
    customerBooking.customerPhone.countryCode
  );
  const sanitizedNationalNumber = sanitizePhoneNumber(
    customerBooking.customerPhone.nationalNumber
  );

  return {
    orderDate: formatDateToString(new Date(customerBooking.orderDate)),
    deliveryDate: formatDateToString(new Date(customerBooking.deliveryDate)),
    bookings,
    customerName: `${customerBooking.firstname} ${customerBooking.surname}`,
    customerEmail: customerBooking.customerEmail,
    customerPhone: `(+${sanitizedContryCode}) ${sanitizedNationalNumber}`,
    soldHere: customerBooking.bookingElements[0].soldHere,
    promoCodeId: promoCode,
  };
}

export function filterServiceCapacityOnMonth(
  serviceCapacity: ServiceCapacity,
  month: number
): CapacityDate[] {
  const result = serviceCapacity.filter((capacityMonth) => {
    return getMonth(parseISO(capacityMonth[0].date)) === month;
  });
  return result[0];
}

/**
 * Compares two date objects to see if they are the same day.
 * @param d1 First date object
 * @param d2 Second date object
 * @returns True or false
 */
export function isSameDay(d1: Date, d2: Date) {
  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  );
}

export function getStepperError(id: StepperErrorId): StepperError {
  let _description;
  switch (id) {
    case StepperErrorId.noStoreOption:
      _description = "Du må velge butikk du vil booke hos.";
      break;
    case StepperErrorId.notAcceptedTerms:
      _description = "Du må godta verkstedsbetingelsene for å fortsette.";
      break;
    case StepperErrorId.noCustomerName:
      _description = "Vennligst fyll inn hele navnet ditt.";
      break;
    case StepperErrorId.noCustomerPhoneNumber:
      _description = "Vennligst fyll inn telefonnummeret ditt.";
      break;
    case StepperErrorId.noCustomerEmail:
      _description = "Vennligst fyll inn din email-addresse.";
      break;
    case StepperErrorId.noBookingElements:
      _description = "Vennligst legg til en sykkel";
      break;
    case StepperErrorId.noBookingElementBrand:
      _description = "Vennligst velg merke.";
      break;
    case StepperErrorId.noBookingDeliveryDate:
      _description = "Vennligst velg en dato.";
      break;
    case StepperErrorId.unverifiedRecaptcha:
      _description =
        "Kunne ikke verifisere ReCaptcha, vennligst last siden inn på nytt og prøv igjen.";
      break;
    default:
      return {
        id: StepperErrorId.unknownError,
        description: "En ukjent feil oppstod.",
      };
  }

  return {
    id,
    description: _description,
  };
}

export const bookingElementHasBrand = (element: BookingElement) => {
  return element.brand !== null;
};

export const validateStepState = (
  state: RootState
): number | { error: StepperError } => {
  const { stepperReducer, bookingReducer } = state;
  const nextStep: number = stepperReducer.activeStep + 1;

  if (!bookingReducer.verifiedRecaptcha)
    return { error: getStepperError(StepperErrorId.unverifiedRecaptcha) };

  switch (stepperReducer.activeStep) {
    case 0:
      if (bookingReducer.booking.storeOption.id === "") {
        return { error: getStepperError(StepperErrorId.noStoreOption) };
      } else if (!bookingReducer.booking.acceptedTerms) {
        return { error: getStepperError(StepperErrorId.notAcceptedTerms) };
      } else return nextStep;
    case 1:
      if (!bookingReducer.booking.bookingElements.length) {
        return { error: getStepperError(StepperErrorId.noBookingElements) };
      } else if (
        !bookingReducer.booking.bookingElements.every(bookingElementHasBrand)
      ) {
        return { error: getStepperError(StepperErrorId.noBookingElementBrand) };
      } else return nextStep;
    case 2:
      if (!bookingReducer.booking.deliveryDate) {
        return { error: getStepperError(StepperErrorId.noBookingDeliveryDate) };
      } else return nextStep;
    case 3:
      if (
        bookingReducer.booking.firstname === "" ||
        bookingReducer.booking.surname === ""
      ) {
        return { error: getStepperError(StepperErrorId.noCustomerName) };
      } else if (bookingReducer.booking.customerPhone.nationalNumber === "") {
        return { error: getStepperError(StepperErrorId.noCustomerPhoneNumber) };
      } else if (bookingReducer.booking.customerEmail === "") {
        return { error: getStepperError(StepperErrorId.noCustomerEmail) };
      } else return nextStep;
    case 4:
      return nextStep;
    case 5:
      return nextStep;
    default:
      return { error: getStepperError(StepperErrorId.unknownError) };
  }
};

export const getBrandsFromBookingElements = (
  bookingElements: BookingElement[]
): string[] => {
  const result = bookingElements.map((el) => {
    if (el.brand) return el.brand.id;
    else return undefined;
  });
  if (checkArrayPrimitiveType(result, "string")) {
    return result as string[];
  } else {
    throw new Error("Could not get brand from booking element");
  }
};

function checkArrayPrimitiveType(array: Array<unknown>, type: string): boolean {
  return array.every((x) => x !== undefined && typeof x === type);
}

export const createUrlParamsFromArray = (
  str: string[],
  key: string
): string => {
  let result = "";
  str.forEach((s, i) => {
    if (i === 0) result += `?${key}[]=${s}`;
    else result += `&${key}[]=${s}`;
  });

  return result;
};

export const addURLParamsFromArray = (
  params: URLSearchParams,
  array: string[],
  key: string
) => {
  array.forEach((s) => {
    params.append(`${key}[]`, s);
  });
};

export function isProductionEnv(): boolean {
  return process.env.NODE_ENV === "production";
}
