export interface StepperError {
  id: StepperErrorId;
  description: string;
}

export enum StepperErrorId {
  noStoreOption = "NO_STORE_OPTION",
  notAcceptedTerms = "NOT_ACCEPTED_TERMS",
  noCustomerName = "NO_CUSTOMER_NAME",
  noCustomerPhoneNumber = "NO_CUSTOMER_PHONE_NUMBER",
  noCustomerEmail = "NO_CUSTOMER_EMAIL",
  noBookingElements = "NO_BOOKING_ELEMENTS",
  noBookingElementBrand = "NO_BOOKING_ELEMENT_BRAND",
  noBookingDeliveryDate = "NO_BOOKING_DELIVERY_DATE",
  bookingNotConfirmed = "BOOKING_NOT_CONFIRMED",
  unverifiedRecaptcha = "UNVERIFIED_RECAPTCHA",
  unknownError = "UNKNOWN_ERROR",
}

/**
 * Fatal API-error, usually triggered by an error in fetching data where the backend is unavailable.
 */
export type API_ERROR = {
  errorCode: number;
  errorMessage: string;
};
