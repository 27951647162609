import { FunctionComponent } from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";

import { BookingElement, Brand } from "../../types/bookingTypes";
import DropdownMenu from "../FormControls/DropdownMenu/DropdownMenu";
import { convertBrandsToKeyValuePairs } from "../../utils/utils";
import MultilineInput from "../FormControls/MultilineInput/MultilineInput";
import FormInlineErrorContainer from "../../containers/FormInlineErrorContainer/FormInlineErrorContainer";

export type Props = {
  bookingElement: BookingElement;
  brands: Brand[];
  checkBoxTitle: string;
  hideCheckbox: boolean;
  onRemove: () => void;
  onChangeSelectedBrand: (value: string) => void;
  onChangeDescription: (value: string) => void;
  onCheckboxClick: () => void;
};

/**
 * Component that represents a booking element
 * @param brands List of brands to include in the dropdown menu
 */
const BookingElementCard: FunctionComponent<Props> = ({
  bookingElement,
  brands,
  checkBoxTitle,
  hideCheckbox,
  onRemove,
  onChangeSelectedBrand,
  onChangeDescription,
  onCheckboxClick,
}) => {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <Button
        onClick={onRemove}
        className={styles.remove}
        aria-label="Fjern booking-element"
        data-cy="remove-booking-element-button"
      >
        Fjern
      </Button>
      <div className={styles.formInputs}>
        <div>
          <Typography variant="h3" className={styles.formLabelText}>
            Merke
          </Typography>

          <DropdownMenu
            label="Velg merke"
            value={bookingElement.brand?.id ?? ""}
            onChange={onChangeSelectedBrand}
            dropdownItems={convertBrandsToKeyValuePairs(brands)}
            aria-label="Velg merke"
          />
        </div>
        {!bookingElement.brand && (
          <div style={{ marginTop: 5 }}>
            <FormInlineErrorContainer />
          </div>
        )}
        <div className={styles.formGroup}>
          <Typography variant="h3" className={styles.formLabelText}>
            Hva skal gjøres?
          </Typography>
          <MultilineInput
            name={"booking-element-description"}
            dataCy={"bike-info-comment-field"}
            initValue={bookingElement.description}
            label="Beskrivelse"
            onChange={onChangeDescription}
            placeholder="Beskriv hva som skal gjøres (valgfritt)"
          />
        </div>
        {!hideCheckbox && (
          <div className={styles.formGroup}>
            <FormControlLabel
              control={
                <Checkbox
                  onClick={onCheckboxClick}
                  checked={bookingElement.soldHere}
                />
              }
              label={checkBoxTitle}
            />
          </div>
        )}
      </div>
    </div>
  );
};
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: "#F7F7F7",
    padding: "20px 15px",
    borderRadius: 4,
    position: "relative",
  },
  formInputs: {
    display: "flex",
    flexDirection: "column",
  },
  brandDropdown: {
    [theme.breakpoints.down("xs")]: {
      maxWidth: 100,
    },
  },
  formGroup: {
    marginTop: 15,
  },
  formLabelText: {
    color: "#333333",
    marginBottom: 5,
  },
  remove: {
    position: "absolute",
    right: 15,
    top: 15,
    color: "#AAAAAA",
  },
}));

export default BookingElementCard;
