import { doc, getDoc } from "firebase/firestore";
import { db } from "../../configs/firebase";

import {
  BookingElement,
  CustomerBooking,
  ServiceDetails,
  StoreOption,
} from "../../types/bookingTypes";
import { ServiceCapacity } from "../../types/calendarTypes";
import { AppThunk } from "../../types/reduxTypes";
import {
  addURLParamsFromArray,
  createBookingPostRequestsPromises,
  getBrandsFromBookingElements,
  isProductionEnv,
  isSameDay,
} from "../../utils/utils";
import { validateBookingElement } from "../../utils/validation";
import { nextStep } from "./stepper";
import { API_ERROR } from "../../types/errorTypes";
import shortid from "shortid";

const SEND_BOOKING = "SEND_BOOKING";
const BOOKING_SUCCEEDED = "BOOKING_SUCCEEDED";
const BOOKING_FAILED = "BOOKING_FAILED";

const SET_STORE_OPTION = "SET_STORE_OPTION";
const ACCEPTED_TERMS = "ACCEPTED_TERMS";
const ADD_BOOKING_ELEMENT = "ADD_BOOKING_ELEMENT";
const REMOVE_BOOKING_ELEMENT = "REMOVE_BOOKING_ELEMENT";

const SET_DELIVERY_DATE = "SET_DELIVERY_DATE";
const SET_BOOKING_INFO = "SET_BOOKING_INFO";
const SET_BOOKING_ELEMENT_INFO = "SET_BOOKING_ELEMENT_INFO";

const GET_SERVICE_DETAILS_STARTED = "GET_SERVICE_DETAILS_STARTED";
const GET_SERVICE_DETAILS_SUCCESS = "GET_SERVICE_DETAILS_SUCCESS";
const GET_SERVICE_DETAILS_FAILED = "GET_SERVICE_DETAILS_FAILED";

const GET_SERVICE_CAPACITY_STARTED = "GET_SERVICE_CAPACITY_STARTED";
const GET_SERVICE_CAPACITY_SUCCESS = "GET_SERVICE_CAPACITY_SUCCESS";
const GET_SERVICE_CAPACITY_FAILED = "GET_SERVICE_CAPACITY_FAILED";

export const VERIFIED_RECAPTCHA = "VERIFIED_RECAPTCHA";

export const VERIFY_PROMO_CODE_STARTED = "VERIFY_PROMO_CODE_STARTED";
export const VERIFY_PROMO_CODE_SUCCESS = "VERIFY_PROMO_CODE_SUCCESS";
export const VERIFY_PROMO_CODE_FAILED = "VERIFY_PROMO_CODE_FAILED";

// Sending bookings
const sendBookingStarted = () => ({
  type: SEND_BOOKING,
});
const sendBookingSucceeded = () => ({
  type: BOOKING_SUCCEEDED,
});
const sendBookingFailed = (error: API_ERROR) => ({
  type: BOOKING_FAILED,
  payload: { error },
});

export const setStoreOption = (storeOption: StoreOption) => ({
  type: SET_STORE_OPTION,
  payload: { storeOption },
});

// Accepted terms
export const acceptedTerms = (accepted: boolean) => ({
  type: ACCEPTED_TERMS,
  payload: { accepted },
});

// Loading bookings
const getServiceCapacityStarted = () => ({
  type: GET_SERVICE_CAPACITY_STARTED,
});
const getServiceCapacitySuccess = (serviceCapacity: ServiceCapacity) => ({
  type: GET_SERVICE_CAPACITY_SUCCESS,
  payload: {
    serviceCapacity,
    loadingBookings: false,
  },
});
const getServiceCapacityFailed = (error: any) => ({
  type: GET_SERVICE_CAPACITY_FAILED,
  payload: { error },
});

export const addBookingElement = (bookingElement: BookingElement) => ({
  type: ADD_BOOKING_ELEMENT,
  payload: { bookingElement },
});

export const removeBookingElement = (id: string) => ({
  type: REMOVE_BOOKING_ELEMENT,
  payload: { id },
});

export const setBookingInfo = <T>(field: string, value: T) => ({
  type: SET_BOOKING_INFO,
  payload: { field, value },
});

export const setBookingElementInfo = <T>(
  field: string,
  value: T,
  id: string,
  resetDeliveryDate?: boolean
) => ({
  type: SET_BOOKING_ELEMENT_INFO,
  payload: { field, value, id, resetDeliveryDate },
});

export const setDeliveryDate = (date: Date) => ({
  type: SET_DELIVERY_DATE,
  payload: { date: date.toISOString() },
});

const getServiceDetailsStarted = () => ({
  type: GET_SERVICE_DETAILS_STARTED,
});

const getServiceDetailsSuccess = (
  serviceDetails: ServiceDetails,
  apiURL: string,
  storeOptions: StoreOption[],
  confirmedBookingMessage: string
) => ({
  type: GET_SERVICE_DETAILS_SUCCESS,
  payload: { serviceDetails, apiURL, storeOptions, confirmedBookingMessage },
});

const getServiceDetailsFailed = (error: API_ERROR) => ({
  type: GET_SERVICE_DETAILS_FAILED,
  payload: { error },
});

export const verifiedRecaptchaSuccess = () => ({
  type: VERIFIED_RECAPTCHA,
});

export const verifyPromoCodeSuccess = (
  promoCode: {
    id: string;
    name: string;
  } | null
) => ({
  type: VERIFY_PROMO_CODE_SUCCESS,
  payload: { promoCode },
});

// Thunks --------------------------

export const getServiceDetails = (): AppThunk => async (dispatch) => {
  dispatch(getServiceDetailsStarted());

  try {
    const bookingInfoRef = doc(db, "public", "bookingInfo");
    const bookingInfoSnap = await getDoc(bookingInfoRef);

    if (bookingInfoSnap.exists()) {
      const bookingInfo = bookingInfoSnap.data();
      const serviceDetails: ServiceDetails = {
        brandOptions: bookingInfo.brandOptions,
        termsOfConditions: bookingInfo.terms,
      };

      dispatch(
        getServiceDetailsSuccess(
          serviceDetails,
          bookingInfo.apiURL,
          bookingInfo.storeOptions,
          bookingInfo.confirmedBookingMessage
        )
      );
    } else {
      throw Error("Feil i henting av data.");
    }
  } catch (error) {
    dispatch(
      getServiceDetailsFailed({
        errorCode: 500,
        errorMessage: "En ukjent feil oppstod.",
      })
    );
  }
};

/**
 * Thunk for getting the existing bookings and their capacity
 */
export const getServiceCapacity =
  (bookingElements: BookingElement[]): AppThunk =>
  async (dispatch, getState) => {
    dispatch(getServiceCapacityStarted());

    try {
      const brands = getBrandsFromBookingElements(bookingElements);
      const storeName = getState().bookingReducer.booking.storeOption.id;
      const promoCode = getState().bookingReducer.promoCode;
      const soldHere = bookingElements.some((e) => e.soldHere);

      const headers = new Headers();
      headers.append("Content-Type", "application/json");

      const options = {
        method: "GET",
        headers,
      };

      const params = new URLSearchParams();
      addURLParamsFromArray(params, brands, "brand");
      params.append("storeName", storeName);
      params.append("soldAtStore", soldHere.toString());
      if (promoCode) params.append("promoCode", promoCode.name);

      if (!bookingElements.every(validateBookingElement))
        throw new Error("Invalid booking element"); // Replace error handling

      const baseURL = isProductionEnv()
        ? getState().bookingReducer.resources.apiURL
        : "http://localhost:3001/v1/";
      const url = baseURL + "serviceCapacity?" + decodeURI(params.toString());

      const response = await fetch(url, options);
      const data: ServiceCapacity = await response.json();

      if (response.status === 200) {
        dispatch(getServiceCapacitySuccess(data));
      } else {
        if (!response.ok) console.error(response.statusText);
        const error = {
          errorCode: response.status,
          errorMessage: "En ukjent feil oppstod.",
        };
        dispatch(getServiceCapacityFailed(error));
      }
    } catch (error) {
      dispatch(
        getServiceCapacityFailed({
          errorCode: 500,
          errorMessage: "En ukjent feil oppstod.",
        })
      );
    }
  };

/**
 * Thunk for creating and inserting a new booking(s) to the database.
 */
export const createBooking = (): AppThunk => async (dispatch, getState) => {
  dispatch(sendBookingStarted());
  try {
    const bookingState = getState().bookingReducer;

    const promoCode = bookingState.promoCode ?? undefined;

    const storeName = bookingState.booking.storeOption.id;

    const URL = isProductionEnv()
      ? (bookingState.resources.apiURL as string)
      : "http://localhost:3001/v1/";

    const promises: Promise<Response>[] = createBookingPostRequestsPromises(
      storeName,
      bookingState.booking,
      URL,
      promoCode?.id
    );

    const result = await Promise.all(promises);

    const error = result.every((response) => {
      return response.status !== 201;
    });

    if (!error) {
      dispatch(nextStep());
      dispatch(sendBookingSucceeded());
    } else {
      const errors: API_ERROR[] = result.map((error) => {
        if (!error.ok) console.error(error);
        return {
          errorCode: error.status,
          errorMessage: "Kunne ikke bekrefte bookingen.",
        };
      });
      dispatch(sendBookingFailed(errors[0]));
    }
  } catch (error: any) {
    dispatch(sendBookingFailed(error.message));
  }
};

type InitialState = {
  sendingBooking: boolean;
  bookingConfirmed: boolean;
  errorMessage: string | null;
  booking: CustomerBooking;
  verifiedRecaptcha: boolean;
  promoCode: { id: string; name: string } | null;
  resources: {
    serviceDetails: ServiceDetails | null;
    apiURL: string | null;
    storeOptions: StoreOption[];
    loadingServiceCapacity: boolean;
    serviceCapacity: ServiceCapacity | null;
    confirmedBookingMessage: string | null;
  };
  bikeboxAvailable: boolean;
};

const initialState: InitialState = {
  bikeboxAvailable: false,
  sendingBooking: false,
  bookingConfirmed: false,
  errorMessage: null,
  promoCode: null,
  booking: {
    deliveryDate: null,
    orderDate: new Date().toISOString(),
    acceptedTerms: false,
    bookingElements: [
      {
        id: shortid.generate(),
        brand: null,
        description: "",
        type: "bike",
        soldHere: false,
      },
    ],
    firstname: "",
    surname: "",
    customerEmail: "",
    customerPhone: {
      countryCode: "+47",
      nationalNumber: "",
    },
    storeOption: {
      name: "",
      id: "",
      serviceEmail: "",
      promoCodesActive: false,
      prioritizeSoldBikes: false,
    },
  },
  verifiedRecaptcha: false,
  resources: {
    serviceDetails: null,
    loadingServiceCapacity: false,
    serviceCapacity: null,
    storeOptions: [],
    apiURL: null,
    confirmedBookingMessage: null,
  },
};

export default function bookingReducer(
  state = initialState,
  action: any
): InitialState {
  const { payload, type } = action;
  switch (type) {
    case SEND_BOOKING:
      return {
        ...state,
        sendingBooking: true,
      };
    case BOOKING_SUCCEEDED:
      return {
        ...state,
        sendingBooking: false,
        bookingConfirmed: true,
        errorMessage: null,
      };
    case BOOKING_FAILED:
      return {
        ...state,
        sendingBooking: false,
        errorMessage: payload.error.errorMessage,
        bookingConfirmed: false,
      };
    case SET_STORE_OPTION:
      return {
        ...state,
        booking: {
          ...state.booking,
          storeOption: payload.storeOption,
        },
      };
    case ACCEPTED_TERMS:
      return {
        ...state,
        booking: {
          ...state.booking,
          acceptedTerms: payload.accepted,
        },
      };
    case ADD_BOOKING_ELEMENT:
      const y = [...state.booking.bookingElements];
      y.push(payload.bookingElement);
      return {
        ...state,
        booking: {
          ...state.booking,
          bookingElements: y,
        },
      };
    case REMOVE_BOOKING_ELEMENT:
      const x = [...state.booking.bookingElements];
      const c = x.filter((e) => e.id !== payload.id);
      return {
        ...state,
        booking: {
          ...state.booking,
          bookingElements: c,
        },
      };
    case SET_BOOKING_INFO:
      const key = payload.field as keyof CustomerBooking;
      const obj = { [key]: payload.value };
      return {
        ...state,
        booking: {
          ...state.booking,
          ...obj,
        },
      };
    case SET_BOOKING_ELEMENT_INFO:
      const resetDeliveryDate = payload.resetDeliveryDate ?? false;
      const bookingElements = [...state.booking.bookingElements].map((e) => {
        const element: BookingElement = { ...e };
        if (element.id === payload.id) {
          const key: keyof BookingElement = payload.field;
          element[key] = payload.value as never;
          return element;
        }
        return element;
      });
      const newState = {
        ...state,
        booking: {
          ...state.booking,
          bookingElements,
        },
      };
      if (resetDeliveryDate) newState.booking.deliveryDate = null;
      return newState;
    case SET_DELIVERY_DATE:
      let deliveryDate: null | string = null;
      if (state.booking.deliveryDate !== null) {
        deliveryDate = isSameDay(
          new Date(payload.date),
          new Date(state.booking.deliveryDate)
        )
          ? null
          : payload.date;
      } else {
        deliveryDate = payload.date;
      }
      return {
        ...state,
        booking: {
          ...state.booking,
          deliveryDate,
        },
      };
    case GET_SERVICE_CAPACITY_STARTED:
      return {
        ...state,
        resources: {
          ...state.resources,
          loadingServiceCapacity: true,
        },
      };
    case GET_SERVICE_CAPACITY_SUCCESS:
      return {
        ...state,
        resources: {
          ...state.resources,
          loadingServiceCapacity: false,
          serviceCapacity: payload.serviceCapacity,
        },
      };
    case GET_SERVICE_CAPACITY_FAILED:
      return {
        ...state,
        errorMessage: payload.error,
      };
    case GET_SERVICE_DETAILS_SUCCESS:
      return {
        ...state,
        resources: {
          ...state.resources,
          serviceDetails: payload.serviceDetails,
          apiURL: payload.apiURL,
          storeOptions: payload.storeOptions,
          confirmedBookingMessage: payload.confirmedBookingMessage,
        },
      };
    case GET_SERVICE_DETAILS_FAILED:
      return {
        ...state,
        errorMessage: payload.error.errorMessage,
      };
    case VERIFIED_RECAPTCHA:
      return {
        ...state,
        verifiedRecaptcha: true,
      };
    case VERIFY_PROMO_CODE_SUCCESS:
      return {
        ...state,
        promoCode: payload.promoCode,
      };
    default:
      return state;
  }
}
