import { FunctionComponent } from "react";
import FormInlineError from "../../components/FormControls/FormInlineError/FormInlineError";
import { useSelector } from "../../hooks/useSelector";
import { StepperError, StepperErrorId } from "../../types/errorTypes";

type Props = {};

const FormInlineErrorContainer: FunctionComponent<Props> = () => {
  const error = useSelector((state) => state.stepperReducer.stepError);

  const includedErrors = [StepperErrorId.noBookingElementBrand];

  return (
    <>
      {error && includedErrors.includes(error.id) && (
        <FormInlineError description={error.description} />
      )}
    </>
  );
};

export default FormInlineErrorContainer;
